.olha-theme {
  color: var(--text);
  font-family: var(--font-family);



  .btn {
    border-radius: 60px;
    box-shadow: none;
    border: none;
    min-width: 8rem;
    font-family: var(--headers-font-family);
    font-weight: 700;
    font-size: 0.875rem;

    &.btn-large {
      min-width: 12rem;
      min-height: 3rem;
    }
  }



  .btn-primary, .btn-gradient-primary {
    /* Green */
    background: var(--main);
    color: #fff;

    &:hover {
      background: var(--text);
    }

    @media (hover: none) {
      &:hover {
        background: var(--main);
      }
    }

    &.disabled, &:disabled {
      background: var(--disabled-green);
    }
  }

  .btn-secondary {
    border: 2px solid var(--main);
    box-sizing: border-box;
    background-color: #FFFFFF;
    color: var(--main);

    &:hover {
      border-color: var(--text) !important;
      background-color: #FFFFFF !important;
      color: var(--text) !important;
    }

    @media (hover: none) {
      &:hover {
        border-color: var(--main) !important;
        background-color: #FFFFFF !important;
        color: var(--main) !important;
      }
    }

    &.disabled, &:disabled {
      background-color: #FFFFFF;
      border: 2px solid #7CBFAE;
      color: #7CBFAE;
    }
  }

  .btn-link {
    color: var(--main) !important;
    text-decoration: underline;
    font-weight: bold;
    padding: 0;
    min-width: auto;
    font-family: var(--headers-font-family);
  }

  h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
    font-style: normal;
    font-weight: 700;
    font-family: var(--headers-font-family);
    color: var(--text);

    &.light-weight {
      font-weight: 600;
    }
  }



  h1, .h1 {
    font-size: 34px;
    line-height: 130%;
    color: var(--text);
  }

  h2, .h2 {
    font-size: 26px;
    line-height: 140%;
    color: var(--text);
  }

  h3, .h3 {
    font-size: 20px;
    line-height: 130%;
    color: var(--text);
  }

  h4, .h4 {
    font-size: 16px;
    line-height: 130%;
    color: var(--text);
  }

  h5, .h5 {
    font-size: 14px;
    line-height: 130%;
    color: var(--text);
  }

  h6, .h6 {
    font-size: 12px;
    line-height: 130%;
    color: var(--text);
  }

  .bg-1 {
    background-color: var(--bg-1);
    border-radius: 20px;
  }

  .bg-2 {
    background-color: var(--bg-2);
    border-radius: 20px;
  }

  .bg-3 {
    background-color: var(--bg-3);
    border-radius: 20px;
  }

  // Temporary classes
  .fs-16 {
    font-size: 16px;
  }

  .fs-14 {
    font-size: 14px;
  }

  .fs-12 {
    font-size: 12px;
  }
  // Temporary classes END

  /* Angular Material overrides */
  .mat-input-element {
    font-size: 16px;
  }

  .mat-form-field-label {
    font-size: 16px;
  }

  .mat-form-field.mat-focused .mat-form-field-label {
    color: var(--main);
  }

  .mat-form-field.mat-form-field-invalid .mat-form-field-label {
    color: var(--accent-2);
  }

  // checkbox
  .mat-checkbox-checked.mat-accent .mat-checkbox-ripple .mat-ripple-element {
    background-color: var(--main) !important;
  }

  .mat-checkbox, .mat-radio-button {
    font-family: var(--font-family);
  }

  .mat-checkbox-checked.mat-accent .mat-checkbox-background,.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
    background-color: var(--main);
  }

  .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle , .mat-radio-outer-circle {
    border-color: var(--main) !important;
  }

  .mat-radio-button.mat-accent .mat-radio-inner-circle{
    background-color: var(--main) !important;
  }

  // Background
  .mat-form-field-outline {
    background-color: var(--bg-white);
    border-radius: 5px;
  }

  .mat-form-field-appearance-outline .mat-form-field-outline {
    color: var(--bg-white);
  }

  // Border hover
  .mat-form-field-appearance-outline .mat-form-field-outline-thick {
    color: var(--main);
  }

  // Border invalid
  .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
    color: var(--accent-2);
  }

  .mat-error {
    color: var(--accent-2);
  }

  .schedule-session {
    .mat-form-field {
      width: 100%;
    }
    .mat-form-field-appearance-outline .mat-form-field-outline {
      background-color: var(--bg-white);
      //border-radius: 1.5rem;
      color: #CCE9E0;
    }
    .mat-form-field-appearance-outline .mat-form-field-outline-start {
      border-bottom-left-radius: 0.4rem;
      border-top-left-radius: 0.4rem;
      min-width: 0.4rem;
    }
    .mat-form-field-appearance-outline .mat-form-field-outline-end {
      border-bottom-right-radius: 0.4rem;
      border-top-right-radius: 0.4rem;
      min-width: 0.4rem;
    }

    .mat-form-field-appearance-outline .mat-form-field-infix {
      padding: 0.4rem;
      border-bottom: 0.5em solid transparent;
    }

    .mat-select-value-text, .mat-form-field-label {
      font-family: var(--font-family) !important;
      color: var(--text);
      font-weight: 400;
    }


    .timezone-selector, .language-selector, .label-selector {
      .mat-form-field-outline {
        background-color: #ECF6F4;
      }
      .mat-form-field-appearance-outline .mat-form-field-outline {
        color: #ECF6F4;
      }
    }


  }


  /* Angular Material overrides END */

  .organization-logo {
    display: inline-flex;
    flex-direction: column;
    text-align: center;
  }

  .organization-logo .image {
    width: 70px;
    height: 70px;
  }

  .organization-logo .name {
    font-size: 20px;
  }
  .text-main {
    color: var(--main) !important;
  }
  .text-success {
    color: var(--main) !important;
  }

  .text-warning {
    color: var(--accent-1) !important;
  }

  .text-danger {
    color: var(--accent-2) !important;
  }

  .text-light {
    color: var(--green-light) !important;
  }
  .text-lighter {
    color: var(--green-lighter) !important;
  }
  .text-dark {
    color: var(--green-dark) !important;
  }

  span.disabled, div.disabled {
    color: var(--disabled-green) !important;
  }

  .border {
    border-radius: 0.5rem;
  }

  .border-success {
    border-color: var(--main) !important;
  }

  .border-warning {
    border-color: var(--accent-1) !important;
  }

  .border-danger {
    border-color: var(--accent-2) !important;
  }

  .bg-success {
    background-color: var(--main) !important;
  }

  .bg-warning {
    background-color: var(--accent-1) !important;
  }

  .bg-danger {
    background-color: var(--accent-2) !important;
  }

  .border-0 {
    border-width: 0 !important;
  }

  .border-1 {
    border-width: 1px !important;
  }

  .border-2 {
    border-width: 2px !important;
  }

  .border-3 {
    border-width: 3px !important;
  }

  .border-4 {
    border-width: 4px !important;
  }

  .border-5 {
    border-width: 5px !important;
  }

  .form-control {
    background-color: var(--bg-white);
    color: var(--main);
    border-radius: 0.5rem;
    border: 0;

    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: var(--main);
      opacity: 0.8;
    }

    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: var(--main);
      opacity: 0.8;
    }

    &::-ms-input-placeholder { /* Microsoft Edge */
      color: var(--main);
      opacity: 0.8;
    }
  }

  .popover {
    border-radius: 1rem;
    padding-right: 1rem;
    padding-left: 1rem;
    max-width: 25rem;
    border: 0;
  }

  .container.bg-2 {
    margin: 90px 20px 295px;
    height: auto;
    width: auto;
    max-width: 1500px;
    padding-top: 30px;
    text-align: center;
  }

  @include media-breakpoint-up(md) {
    .container.bg-2 {
      margin: 120px auto 131px;
      padding-top: 60px;
      height: 720px;
    }
  }

  .card {
    background-color: var(--bg-white);
    border-radius: 1.5rem;
    border: 0 solid #fff;
    box-shadow: none;

    .card-body {
      padding: 1.5rem;
    }
    &.dashboard-card {
      border-radius: 10px;
      border: 1px solid #CEDBD7;
      margin: 0 !important;
      height: 100%;
    }
  }

  .pointer {
    cursor: pointer;
  }

}

/* These classes will be applied to elements that directly append to the body tag */

.modal-content {
  border-radius: 1rem;
}

.schedule-session-selectors-dropdown-panel {
  border-radius: 0.3rem !important;
  margin-top: 30px !important;
  margin-left: 25px !important;
  .mat-option {
    color: #44A38D !important;
    .emoji-mart-emoji span {
      left: -.15em;
      top: -.1em;
    }
  }

  .mat-pseudo-checkbox {
    border: 1px solid rgba(10, 74, 58, 0.2) !important;
    border-radius: 4px !important;
  }

}

.mat-primary .mat-pseudo-checkbox-checked, .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #44A38D !important;
  border: 2px solid #44A38D !important;
}

.mat-calendar-body-selected {
  background-color: var(--main) !important;
  color: #FFFFFF !important;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: var(--main) !important;
}
.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: #ECF6F3 !important;
}

.schedule-session-selectors-dropdown-panel.mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0 2px 4px -1px rgb(0 0 0 / 11%), 0px 4px 5px 0px rgb(0 0 0 / 5%), 0px 1px 10px 0px rgb(0 0 0 / 3%);
}

.collapsed-mode {
  .navbar, .session-chat-header {
    display: none !important;
  }
  .session-chat-container {
    padding-top: 10px !important;
  }

  .chat-container-coach {
    height: 100% !important;
  }

  //.chat-container {
  //  height: calc(100% - 120px) !important;
  //}
  .content-div {
    height: 100% !important;
  }
}
.self-scan-details-modal .modal-content {
  border: 0;
  box-shadow: 0 20px 60px rgba(0, 0, 0, 0.16);
}
@include media-breakpoint-up(lg) {
  .self-scan-details-modal {
    .modal-dialog {
      position: fixed;
      right: 1%;
      top: 10%;
      left: auto;
      margin: auto;
    }
  }
}
